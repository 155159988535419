/***
 *
 *   APP LAYOUT
 *   Main application layout containing the navigation
 *   and header (title, secondary nav and signed in user)
 *
 *   PROPS
 *   title: title of the view
 *   view: view filename
 *
 *
 * older link We've removed
 *   { label: "Dashboard", icon: "activity", link: "/dashboard" },
 *   { label: "Help", icon: "help-circle", link: "/help" },
 **********/

import { Fragment, useContext } from "react";
import { useLocation } from "react-router-dom";
import { AuthContext, AppNav, Blur } from "components/lib";
import Style from "./app.module.scss";

export function AppLayout(props) {
  // context & style
  const context = useContext(AuthContext);

  // we need to grab the pathname to get the current page
  // we use this to decide if we need to show the history button
  const location = useLocation();
  const currentPageName = location.pathname.split("/")[1]; // Get the first part of the pathname  console.log("currentPageName: ", currentPageName);

  return (
    <Fragment>
      <AppNav
        items={[
          {
            label: "Chat",
            icon: "loader",
            link: "/ai/Chat/",
          },
          { label: "Ai Tools", icon: "paperclip", link: "/tools" },
          { label: "Ai Experts", icon: "users", link: "/experts" },
          {
            label: "Create",
            icon: "zap",
            permission: "supertyro",
            link: "/prompt/designer",
          },
          { label: "History", icon: "clock", link: "/history" },
          { label: "Account", icon: "at-sign", link: "/account" },
          { label: "Sign Out", icon: "log-out", action: context.signout },
        ]}
      />
      <Blur />
      <main className={`${Style.app} ${currentPageName} wide-margin`}>
        {<props.children {...props.data} />}
      </main>
    </Fragment>
  );
}
