/***
 *
 *   SIGN UP STEP 1
 *   Signup form for account owners
 *   Step 1: create account
 *  Step 2: verify email address
 *   Step 3: select plan
 *
 **********/

import React, { useContext } from "react";
import {
  AuthContext,
  Animate,
  Card,
  SocialSignin,
  Form,
  Link,
} from "components/lib";

export function Signup(props) {
  const context = useContext(AuthContext);

  return (
    <Animate type="pop">
      <Card
        loading={false}
        restrictWidth
        center
        title="Create Your Account
"
      >
        <Form
          inputs={{
            name_first: {
              label: "First Name",
              type: "text",
              required: true,
              errorMessage: "Please enter your first name",
            },
            name_last: {
              label: "Last Name",
              type: "text",
              required: true,
              errorMessage: "Please enter your last name",
            },
            email: {
              label: "Email",
              type: "email",
              required: true,
            },
            password: {
              label: "Password",
              type: "password",
              required: true,
              complexPassword: false,
            },
            confirm_password: {
              type: "hidden",
              value: null,
            },
          }}
          url="/api/account"
          method="POST"
          buttonText="Create Account"
          callback={context.signin}
        />
      </Card>
      <div className="mt-4">
        <br />
        Already registered? <Link url="/signin" text="Sign In" />
      </div>
    </Animate>
  );
}
