/***
 *
 *   SIGN IN
 *   Sign in form for all account types (including master).
 *
 **********/

import React, { useContext, useState, useEffect } from "react";
import {
  Animate,
  AuthContext,
  ViewContext,
  Button,
  Form,
  Card,
  Link,
  Message,
  useLocation,
  useNavigate,
  SocialSignin,
} from "components/lib";

export function Signin(props) {
  // context
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const location = useLocation();
  const navigate = useNavigate();

  // state
  const [useMagic, setUseMagic] = useState(false);
  const [magicLinkSent, setMagicLinkSent] = useState(false);
  const [form, setForm] = useState({
    email: {
      label: "Email",
      type: "email",
      required: true,
    },
    password: {
      label: "Password",
      type: "password",
      required: true,
    },
    forgotpassword: {
      type: "link",
      url: "/forgotpassword",
      text: "Forgot your password?",
    },
  });

  useEffect(() => {
    // was an error message passed from the server router?
    const qs = location.search;

    if (qs.includes("error")) {
      const msg = decodeURIComponent(qs.substring(qs.indexOf("?error=") + 7));
      viewContext.notification.show(msg, "error");
    }
  }, [location.search]);

  function toggleMagicLink(useMagic) {
    const f = { ...form };
    f.password.type = useMagic ? "hidden" : "password";
    f.password.required = useMagic ? false : true;
    f.forgotpassword.type = useMagic ? "hidden" : "link";

    setForm(f);
    setUseMagic(useMagic);
  }

  return (
    <Animate type="pop">
      <div className="mt-4">
        Don't have an account? <Link url="/signup" text="Sign Up" />
      </div>
      <br />
      {magicLinkSent ? (
        <div style={{ width: "28em", margin: "0 auto" }}>
          <Message
            title="Check Your Email"
            type="success"
            text="Please click the link in your email to sign in."
          />
        </div>
      ) : (
        <>
          <Card restrictWidth center title="Sign in to Tyro">
            <Form
              inputs={form}
              method="POST"
              url={useMagic ? "/api/auth/magic" : "/api/auth"}
              buttonText={useMagic ? "Send Magic Link" : "Sign In"}
              callback={(res) => {
                useMagic
                  ? setMagicLinkSent(true)
                  : res.data["2fa_required"]
                  ? navigate(`/signin/otp?token=${res.data.token}`)
                  : navigate(authContext.signin(res));
              }}
            />
          </Card>
          <br />
          <Card restrictWidth center>
            {useMagic ? (
              <Button
                text="Use password"
                color="blue"
                icon="shield"
                iconColor="white"
                className="mx-auto mb-5"
                rounded
                iconButton
                small
                action={() => {
                  toggleMagicLink(false);
                }}
              />
            ) : (
              <Button
                text="Send a sign in link to your email"
                color="blue"
                icon="send"
                iconColor="white"
                className="mx-auto mb-5"
                rounded
                iconButton
                small
                action={() => {
                  toggleMagicLink(true);
                }}
              />
            )}
          </Card>
          <br />
          <Card restrictWidth center transparent>
            <SocialSignin
              network={["clever", "classlink"]}
              useMagic={useMagic}
            />
          </Card>
        </>
      )}
    </Animate>
  );
}
