import { Fragment, useState, useEffect } from "react";
import { Loader } from "components/lib";
import Style from "./promptForm.module.scss";

export function PromptForm(onChange, onSubmit, defaultText, inputValue) {
  const [state, setState] = useState();

  useEffect(() => {}, []);

  function handleChange(event) {
    handleChange(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    handleSubmit();
  }

  return (
    <div id="questionContainer" className={Style.promptForm}>
      <form id="questionForm" method="post" onSubmit={(e) => handleSubmit(e)}>
        <textarea
          id="question"
          type="text"
          required
          default={defaultText || ""}
          onChange={onChange}
          value={inputValue || ""}
        ></textarea>

        <button type="submit">&#8594;</button>
      </form>
    </div>
  );
}
