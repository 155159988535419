/***
 *
 *   HOMEPAGE
 *   Homepage template with features, testimonials and CTA
 *
 **********/

import React, { Fragment, useContext, useRef } from "react";
import { Animate, AuthContext, LoaderChat } from "components/lib";
import Axios from "axios";

export function Home(props) {
  const context = useContext(AuthContext);

  // kinda like getElementById
  const loaderRef = useRef(null);
  const messageRef = useRef(null);
  const formRef = useRef(null);

  var submitting = false;

  async function sendEmail(event) {
    // Prevent the browser from reloading the page
    event.preventDefault();

    var message = "";
    const form = event.target;
    var formData = {};

    for (let i = 0; i < form.elements.length; i++) {
      const element = form.elements[i];
      formData[element.name] = element.value;
    }

    if (submitting) return;

    submitting = true;
    formRef.current.style.opacity = "0";
    loaderRef.current.style.opacity = "1";

    await Axios({
      method: "post",
      url: "/api/utility/mailWaitList",
      params: formData,
    })
      .then((response) => {
        message = "Thank you. We'll be in touch soon.";
      })
      .catch((error) => {
        message = "An error occurred. Please try again later.";
      });

    setTimeout(() => {
      loaderRef.current.style.opacity = "0";
      messageRef.current.style.opacity = "1";
      messageRef.current.textContent = message;
    }, 1000);

    submitting = false;
  }

  return (
    <Fragment>
      <Animate type="slideup"></Animate>
      <section className="bottom">
        <div className="waitList">
          <div className="message" ref={messageRef}></div>

          <div className="header">
            <h2>Are you interested in bringing AI into the classroom?</h2>
            <p>
              Share a bit about yourself, and we'll reach out with details on
              how to experience tyro.ai yourself.
            </p>
          </div>
          <form onSubmit={sendEmail} ref={formRef}>
            <input name="name" required type="text" placeholder="Name" />
            <input
              name="email"
              required
              pattern="[a-zA-Z0-9._\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}"
              type="email"
              placeholder="Email"
            />
            <br />
            <input
              name="school"
              required
              type="text"
              placeholder="School or Organization"
            />
            <input name="role" required type="text" placeholder="Role" />
            <br />
            <textarea
              name="message"
              required
              placeholder="In one to three sentences, tell us why you're intested in using ai in education? "
            />
            <button name="submit" type="submit">
              Submit
            </button>
          </form>
          <div className="loader" ref={loaderRef}>
            <LoaderChat />
          </div>
        </div>
      </section>
    </Fragment>
  );
}
