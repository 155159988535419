/***
 *
 *   HOME NAV
 *   Navigation used on the main external website. Renders a dashboard link
 *   if the user is signed in, or a sign up link if they are not
 *
 **********/

import { useContext } from "react";
import { AuthContext, Logo, Button, ClassHelper } from "components/lib";
import Style from "./auth.module.scss";

export function AuthNav(props) {
  // context
  const context = useContext(AuthContext);

  // get the current path
  const path = window.location.pathname;

  return (
    <nav className={Style.nav}>
      <Logo logo color />

      {context.user?.token || process.env.NODE_ENV === "development" ? (
        <div>
          <span className="hello"> Hello {context.user?.name}! &nbsp;</span>
          <Button small goto="/ai/Chat" text="Enter" className={Style.button} />
        </div>
      ) : (
        ""
      )}
      {/* 
bring back the button - replace the "" above with this:
<Button
          small
          goto={buttonPath}
          text={buttonTxt}
          className={Style.button}
        />

*/}
    </nav>
  );
}
