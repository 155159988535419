/***
 *
 *   HOME LAYOUT
 *   Main website layout
 *
 **********/

import { Fragment } from "react";
import { AuthNav, useLocation } from "components/lib";

export function HomeLayout(props) {
  return (
    <Fragment>
      <main className="home">
        <AuthNav />
        {<props.children {...props.data} />}
      </main>
    </Fragment>
  );
}
