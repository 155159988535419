// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.favoriteButton_base__myjQn {
  background: transparent;
}
.favoriteButton_base__myjQn:hover {
  background: transparent;
}
.favoriteButton_base__myjQn:hover svg {
  opacity: 1;
}
.favoriteButton_base__myjQn svg {
  color: white;
  margin-top: -2px;
}

.favoriteButton_favorite__ICaFM svg {
  opacity: 0.8;
  transition: all 0.2s ease-in-out;
}
.favoriteButton_favorite__ICaFM svg:hover {
  color: white;
}

.favoriteButton_favorited__L3ume svg {
  color: gold;
  fill: gold;
}`, "",{"version":3,"sources":["webpack://./src/components/button/favoriteButton.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;AACE;EACE,uBAAA;AACJ;AAAI;EACE,UAAA;AAEN;AAEE;EACE,YAAA;EACA,gBAAA;AAAJ;;AAIE;EACE,YAAA;EACA,gCAAA;AADJ;AAGI;EACE,YAAA;AADN;;AAOE;EACE,WAAA;EACA,UAAA;AAJJ","sourcesContent":[".base {\n  background: transparent;\n\n  &:hover {\n    background: transparent;\n    svg {\n      opacity: 1;\n    }\n  }\n\n  svg {\n    color: white;\n    margin-top: -2px;\n  }\n}\n.favorite {\n  svg {\n    opacity: 0.8;\n    transition: all 0.2s ease-in-out;\n\n    &:hover {\n      color: white;\n    }\n  }\n}\n\n.favorited {\n  svg {\n    color: gold;\n    fill: gold;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": `favoriteButton_base__myjQn`,
	"favorite": `favoriteButton_favorite__ICaFM`,
	"favorited": `favoriteButton_favorited__L3ume`
};
export default ___CSS_LOADER_EXPORT___;
